import { Button } from 'antd';
import styles from './tfa.less';
import { getMonospacedFont } from '@/global';

export const getBackupCodesDesc = (plain: (text: string, defaultMessage?: string) => string) => plain('If you lose your two-factor authentication method, you can use a backup code instead. Make sure to keep these codes in a safe place.');

const makeBackupCodesDescription = (plain: (text: string, defaultMessage?: string) => string) => <>
    <div className={styles.desc}>
        {getBackupCodesDesc(plain)}
    </div>
</>;

const showBackupCodes = (backupCodes: string[]) => {
    const numRows = Math.ceil(backupCodes.length / 2) + 1;
    const rows = [];

    for (let i = 0; i < numRows; i++) {
        const leftIndex = i * 2;
        const rightIndex = leftIndex + 1;
        const leftString = backupCodes[leftIndex];
        const rightString = rightIndex >= backupCodes.length ? undefined : backupCodes[rightIndex];

        rows.push(
            <div className={styles.rows} key={i} style={{fontFamily: getMonospacedFont()}}>
                <span>{leftString}</span>
                {rightString && <span>{rightString}</span>}
            </div>
        );
    }
    return <div className={styles.codes}>
        {rows}
    </div>;
}

export const makeBackupCodesComponents = (plain: (text: string, defaultMessage?: string) => string, backupCodes: string[]) => <>
    {makeBackupCodesDescription(plain)}
    {showBackupCodes(backupCodes)}
</>;

const handleDownloadCodes = async (codes: string[]) => {
    const content = codes.join('\n');
    const file = new Blob([content], { type: 'text/plain' });

    const a = document.createElement('a');
    const url = URL.createObjectURL(file);
    a.href = url;
    a.download = 'rustdesk_backup_codes.txt';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
}

export const makeBackupCodesSubmitter = (
    plain: (text: string, defaultMessage?: string) => string,
    codes: string[],
    handleModalOpen: (open: boolean) => void) => {
    return {
        render: (props: any, dom: JSX.Element[]) => {
            return <>
                {dom[0]}
                <Button
                    type="primary"
                    onClick={() => {
                        handleDownloadCodes(codes).then(() => {
                            handleModalOpen(false);
                        });
                    }}
                >
                    {plain('Download codes')}
                </Button>
            </>;
        },
    };
}
