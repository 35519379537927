import component from './zh-CN/component';
import globalHeader from './zh-CN/globalHeader';
import menu from './zh-CN/menu';
import pwa from './zh-CN/pwa';
import settingDrawer from './zh-CN/settingDrawer';
import settings from './zh-CN/settings';
import pages from './zh-CN/pages';

export default {
  'app.title': 'RustDesk 控制台',
  'navBar.lang': '语言',
  'layout.user.link.help': '帮助',
  'layout.user.link.privacy': '隐私',
  'layout.user.link.terms': '条款',
  'app.copyright.produced': '马齿苋科技（北京）出品',
  'app.preview.down.block': '下载此页面到本地项目',
  'app.welcome.link.fetch-blocks': '获取全部区块',
  'app.welcome.link.block-list': '基于 block 开发，快速构建标准页面',
  'The verification code is incorrect or has expired': '验证码错误或已超时',
  Type: '类型',
  Remote: '被控端',
  remoteTip: '远程被控制的电脑或其他终端',
  Local: '主控端',
  'Start Time': '开始时间',
  'End Time': '结束时间',
  Note: '备注',
  'Log List': '日志列表',
  'Device List': '设备列表',
  'User List': '用户列表',
  'User Name': '用户名',
  Updating: '正在更新',
  Adding: '正在添加',
  Deleting: '正在删除',
  Create: '创建',
  Add: '添加',
  Confirm: '确认',
  Submit: '提交',
  Warning: '注意',
  Error: '错误',
  Email: '电子邮箱',
  Status: '状态',
  Action: '动作',
  Password: '密码',
  'New Password': '新密码',
  Edit: '编辑',
  Normal: '正常',
  Disabled: '禁用',
  Disable: '禁用',
  Enable: '启用',
  Chosen: '选中',
  Items: '项',
  User: '用户',
  Info: '信息',
  'Log out': '退出登录',
  Settings: '设置',
  'Basic Settings': '基本设置',
  'File Transfer': '文件传输',
  'Port Transfer': '端口转发',
  'Created Time': '创建时间',
  Delete: '删除',
  Group: '群组',
  Name: '名称',
  'Group List': '群组列表',
  deleteConfirm: '确认要删除？',
  groupTip: '同一组用户可以相互访问',
  document: '文档',
  Detail: '详细信息',
  Direction: '方向',
  File: '文件',
  Directory: '目录',
  Disconnect: '断开连接',
  Time: '时间',
  'Export as csv': '导出csv',
  exportCsvTip: '一次最多导出1000条记录',
  'Not binded only': '只显示未绑定设备',
  Close: '关闭',
  'Default Strategy': '默认策略',
  'device num':'设备数量',
  'Device Name': '设备名称',
  'Strategy':'策略',
  'Device Strategy':'设备策略',
  'User Strategy':'用户策略',
  'strategy_tooltip':"'-' 是默认策略，'/' 后面的策略是用户策略，当设备的策略为空时，将应用该用户策略到设备上",
  Search:'搜索',
  ...pages,
  ...globalHeader,
  ...menu,
  ...settingDrawer,
  ...settings,
  ...pwa,
  ...component,
};
