// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import SmileOutlined from '/home/runner/work/rustdesk-server-pro/rustdesk-server-pro/web_hbbs/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/SmileOutlined';
import DesktopOutlined from '/home/runner/work/rustdesk-server-pro/rustdesk-server-pro/web_hbbs/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/DesktopOutlined';
import FileTextOutlined from '/home/runner/work/rustdesk-server-pro/rustdesk-server-pro/web_hbbs/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/FileTextOutlined';
import UserOutlined from '/home/runner/work/rustdesk-server-pro/rustdesk-server-pro/web_hbbs/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/UserOutlined';
import TeamOutlined from '/home/runner/work/rustdesk-server-pro/rustdesk-server-pro/web_hbbs/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/TeamOutlined';
import SolutionOutlined from '/home/runner/work/rustdesk-server-pro/rustdesk-server-pro/web_hbbs/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/SolutionOutlined';
import SettingOutlined from '/home/runner/work/rustdesk-server-pro/rustdesk-server-pro/web_hbbs/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/SettingOutlined';
export default { SmileOutlined, DesktopOutlined, FileTextOutlined, UserOutlined, TeamOutlined, SolutionOutlined, SettingOutlined };
