import React, { useCallback } from 'react';
import { LogoutOutlined, SettingOutlined } from '@ant-design/icons';
import { Avatar, Menu, Spin } from 'antd';
import { history, useModel } from '@umijs/max';
import { stringify } from 'querystring';
import HeaderDropdown from '../HeaderDropdown';
import styles from './index.less';
import { outLogin } from '@/services/api';
import type { MenuInfo } from 'rc-menu/lib/interface';
import { keyAccessToken, t } from '../../global';

export type GlobalHeaderRightProps = {
  menu?: boolean;
  actionClassName: string,
};

export const loginOut = async (payload: Record<string, string | null>) => {
  try {
    await outLogin(payload);
  } catch (e) {
    console.log(e);
  }
  localStorage.removeItem(keyAccessToken);
  sessionStorage.removeItem(keyAccessToken);
  const { query = {}, pathname } = history.location;
  const { redirect } = query;
  // Note: There may be security issues, please note
  if (history.location.pathname !== '/user/login' && !redirect) {
    history.replace({
      pathname: '/user/login',
      search: stringify({
        redirect: pathname,
      }),
    });
  }
};

const AvatarDropdown: React.FC<GlobalHeaderRightProps> = ({ menu, actionClassName }) => {
  const { initialState, setInitialState } = useModel('@@initialState');

  const onMenuClick = useCallback(
    (event: MenuInfo) => {
      const { key } = event;
      if (key === 'logout') {
        setInitialState((s) => ({ ...s, user: undefined }));
        loginOut({});
        return;
      }
      history.push(`/account/${key}`);
    },
    [setInitialState],
  );

  const loading = (
    <span className={`${styles.action} ${styles.account}`}>
      <Spin
        size="small"
        style={{
          marginLeft: 8,
          marginRight: 8,
        }}
      />
    </span>
  );

  if (!initialState) {
    return loading;
  }

  const { user: currentUser, team: currentTeam } = initialState;

  if (!currentUser || !currentUser.name) {
    return loading;
  }

  const menuHeaderDropdown = (
    <Menu className={styles.menu} selectedKeys={[]} onClick={onMenuClick}>
      {menu && (
        <Menu.Item key="settings">
          <SettingOutlined />
          {t('Settings')}
        </Menu.Item>
      )}
      {menu && <Menu.Divider />}

      <Menu.Item key="logout">
        <LogoutOutlined />
        {t('Log out')}
      </Menu.Item>
    </Menu>
  );
  return (
    <HeaderDropdown overlay={menuHeaderDropdown}>
      <span className={`${actionClassName} ${styles.account}`}>
        {currentUser.avatar ? (
          <Avatar size="small" className={styles.avatar} src={currentUser.avatar} alt="avatar" />
        ) : (
          <span style={{ marginRight: 8 }}>
            <Avatar style={{backgroundColor: '#1677ff', verticalAlign: 'middle'}} size='medium'>{currentUser.name[0]}</Avatar>
          </span>
        )}
        <span className={`${styles.name} anticon`}>{currentUser.name}</span>
      </span>
    </HeaderDropdown>
  );
};

export default AvatarDropdown;
