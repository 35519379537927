import React, { useState } from 'react';
import { Button, Form, Typography, message } from 'antd';
import { userTfaEnable, userTfaVerify } from '@/services/api';
import styles from './tfa.less';
import { ModalForm } from '@ant-design/pro-form';
import { QRCodeSVG } from 'qrcode.react';
import { makeBackupCodesSubmitter, makeBackupCodesComponents } from './BackupCodes';
import { TfaCodeComponent } from './tfa';

enum EnableTfaState {
    Verify = 0,
    Enable = 1,
    ShowBackupCodes = 2,
}

type TfaFormProps = {
    otpauth?: string;
    modalOpen: boolean;
    handleModalOpen: (open: boolean, tfa_updated: boolean) => void;
    plain: (text: string, defaultMessage?: string) => string;
};

const EnableTfaForm: React.FC<TfaFormProps> = ({ otpauth, modalOpen, handleModalOpen, plain }) => {
    const [form] = Form.useForm();
    const [isCodeReady, setIsCodeReady] = useState<boolean>(false);
    const [enableState, setEnableState] = useState<EnableTfaState>(otpauth == undefined ? EnableTfaState.Verify : EnableTfaState.Enable);
    const [backupCodes, setBackupCodes] = useState<string[]>([]);
    const [otpauthUrl, setOtpauthUrl] = useState<string | undefined>(otpauth);
    const [newTfaStatus, setNewTfaStatus] = useState<number | undefined>(undefined);

    const getSecret = () => {
        const secretRegex = /secret=([^&]+)/;
        const match = (otpauth || otpauthUrl)!.match(secretRegex);
        return match && match[1];
    }

    const makeSubDescription = () => <div className={styles.desc}>{plain('Make your RustDesk account more secure by requiring an extra authentication code when you log in.')}</div>;
    const makeTitleStep1 = () => <div className={styles.subtitle}>{plain('1. Download an authenticator app')}</div>;
    const makeSubDescriptionStep1 = () => <>
        <div className={styles.desc}>
            {plain('Download an authenticator app sunch as')}
            &nbsp;
            <a target={'https://authy.com/'}>Authy</a>,&nbsp;
            <a target={'https://www.microsoft.com/en-us/security/mobile-authenticator-app/'}>Microsoft</a>
            &nbsp;{plain('or')}&nbsp;
            <a target={'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2/'}>Google</a>
            &nbsp;
            {plain('Authenticator')}
            &nbsp;
            {plain('on your phone or desktop.')}

        </div>
    </>;
    const makeTitleStep2 = () => <div className={styles.subtitle}>{plain('2. Scan the QR code')}</div>;
    const makeSubDescriptionStep2 = () => <>
        <div className={styles.desc}>
            {plain('Open your authenticator app, scan the QR code and enter the code that your app shows.')}
        </div>
    </>;

    const makeCodeComponent = () => <div style={{ marginLeft: '15px' }}>
        <TfaCodeComponent setIsCodeReady={setIsCodeReady} width={240} isBackupCodeAcceptable={false} />
    </div>;

    const makeComponentStep2 = () => <>
        <div className={styles.step2}>
            <div>
                {makeSubDescriptionStep2()}
                <div style={{ height: '15px' }} />
                {makeCodeComponent()}
            </div>
            <div className={styles.qr_code}>
                <QRCodeSVG value={(otpauth || otpauthUrl)!} />
                <br />
                <Typography.Text style={{ fontSize: '12px' }} copyable={{ text: getSecret() || '' }} >
                    {getSecret()}
                </Typography.Text>
            </div>
        </div>
    </>;

    const handleSubmit = async (value: any) => {
        if (enableState === EnableTfaState.Verify) {
            const otpauth_url = await userTfaEnable(value);
            setOtpauthUrl(otpauth_url);
            setEnableState(EnableTfaState.Enable);
            form.setFieldValue('tfa_code', '');
            setNewTfaStatus(0);
        } else {
            try {
                await form.validateFields();
            } catch (error) {
                return;
            }

            try {
                const resp = await userTfaVerify(value);
                if (resp.success) {
                    message.success('Enable 2FA success!');
                    setBackupCodes(resp.backup_codes ?? []);
                    setEnableState(EnableTfaState.ShowBackupCodes);
                    setNewTfaStatus(2);
                } else {
                    message.error('Enable 2FA failed!');
                }
            }
            catch (error: any) {
                message.error(error);
            }
        }
    }

    const makeRemark = () => <>
        <div className={styles.remark}>
            {plain('You can change or remove 2FA at any time in your account settings.')}
        </div>
    </>;

    const makeEnableSubmitter = () => {
        return {
            render: (props: any, dom: JSX.Element[]) => {
                return <>
                    {dom[0]}
                    <Button
                        type="primary"
                        onClick={() => form.submit()}
                        disabled={!isCodeReady}
                    >
                        {plain('Enable 2FA')}
                    </Button>
                </>;
            },
        };
    };

    const makeNewComponents = () => <>
        {makeSubDescription()}
        {makeTitleStep1()}
        {makeSubDescriptionStep1()}
        {makeTitleStep2()}
        {makeComponentStep2()}
        {makeRemark()}
    </>;

    const makeShowBackupCodesComponents = () => <>
        {makeBackupCodesComponents(plain, backupCodes)}
    </>;

    const handleModalOpen2 = (v: boolean) => handleModalOpen(v, !v && newTfaStatus != undefined);

    const getSubmitter = () => {
        if (enableState === EnableTfaState.Verify) {
            return undefined;
        } else if (enableState === EnableTfaState.Enable) {
            return makeEnableSubmitter();
        } else {
            return makeBackupCodesSubmitter(plain, backupCodes, handleModalOpen2);
        }
    }

    const getComponents = () => {
        if (enableState === EnableTfaState.Verify) {
            return <>
                <div style={{ marginBottom: '15px' }}>{plain('Please verify your 2FA code to disable current 2FA first.')}</div>
                <TfaCodeComponent isBackupCodeAcceptable={true} />
            </>;
        } else if (enableState === EnableTfaState.Enable) {
            return makeNewComponents();
        } else {
            return makeShowBackupCodesComponents();
        }
    }

    return modalOpen && <ModalForm
        title={plain('Enable Two-Factor Authentication')}
        open={modalOpen}
        onOpenChange={handleModalOpen2}
        width='620px'
        form={form}
        className={styles.modal}
        onFinish={handleSubmit}
        submitter={getSubmitter()}
    >
        {getComponents()}
    </ModalForm>;
}

export default EnableTfaForm;
